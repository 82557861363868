window.CESIUM_BASE_URL = '/Cesium/';

import * as Cesium from 'cesium';



const typeSelect = document.getElementById('type');

// Initialize Cesium Viewer for 3D
const openStreetMapProvider = new Cesium.ProviderViewModel({
    name: "OpenStreetMap",
    iconUrl: Cesium.buildModuleUrl('Widgets/Images/ImageryProviders/openStreetMap.png'),
    tooltip: "OpenStreetMap",
	creationFunction: function () {
        return new Cesium.OpenStreetMapImageryProvider({
            url: 'https://a.tile.openstreetmap.org/'
        });
    }
});


const cartocdnProvider = new Cesium.ProviderViewModel({
    name: "CartoCDNGrayScaleMap",
    iconUrl: Cesium.buildModuleUrl('Widgets/Images/ImageryProviders/stadiaAlidadeSmooth.png'),
    tooltip: "CartoCDNGrayScaleMap",
    creationFunction: function () {
        return new Cesium.UrlTemplateImageryProvider({
            url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png'
        });
    }
});


const viewer = new Cesium.Viewer('map3d', {
    infoBox: true, // Enables the information box for displaying properties
    creditContainer: document.createElement('div'),
    timeline: false,
    fullscreenButton: false,
    animation: false,
	imageryProviderViewModels: [
		openStreetMapProvider,
		cartocdnProvider
		
    ],
	selectedImageryProviderViewModel: openStreetMapProvider,
	baseLayerPicker: true,
	terrainProviderViewModels: [],
	
});
//convert open street map into Gray scale
const imageryLayers = viewer.imageryLayers;
const layer = imageryLayers.get(0);
layer["saturation"] = 0;

viewer.scene.screenSpaceCameraController.minimumZoomDistance = 10;

/*
const tileset = viewer.imageryLayers.addImageryProvider(
    new Cesium.UrlTemplateImageryProvider({
        url: 'http://{s}.localhost:8080/data/texas_power_lines/{z}/{x}/{y}.png',
        
    })
);*/


let ukGeoJsonDataSource ;

loadPointsGeoJsonSource(ukGeoJsonDataSource, 'UKPowerPlant', '/UK_Power_Plants.geojson', '/bright_green_circle.png', true);


let germanyWindGeoJsonDataSource ;
 loadPointsGeoJsonSource(germanyWindGeoJsonDataSource, 'WindPower', '/Germany_Wind_Power_Plants.geojson', '/marker-blue-icon.png');

//let germanyPowerLineGeoJsonDataSource;
//loadPointsGeoJsonSource(germanyPowerLineGeoJsonDataSource, 'PowerLines', '/germany_power_lines.geojson', Cesium.Color.BLACK);


let germanyNuclearGeoJsonDataSource;
loadPointsGeoJsonSource(germanyNuclearGeoJsonDataSource, 'NuclearPower','/Germany_Nuclear_Power_Plants.geojson', '/marker-green-icon.png');

let germanyThermalGeoJsonDataSource;
loadPointsGeoJsonSource(germanyThermalGeoJsonDataSource, 'ThermalPower', '/Germany_Thermal_Power_Plants.geojson', '/marker-black-icon.png');


let germanyHydroGeoJsonDataSource;
loadPointsGeoJsonSource(germanyHydroGeoJsonDataSource, 'HydroPower', '/Germany_Hydro_Power_Plants.geojson', '/marker-gray-icon.png');


let germanyPhotoVoltaicGeoJsonDataSource;
loadPointsGeoJsonSource(germanyPhotoVoltaicGeoJsonDataSource, 'SolarPower', '/Germany_PhotoVoltaic_Power_Plants.geojson', '/marker-red-icon.png');

//let germanyPowerSubstationsGeoJsonDataSource;
//loadPointsGeoJsonSource(germanyPowerSubstationsGeoJsonDataSource, 'PowerSubstations', '/germany_power_substations.geojson', Cesium.Color.DARKSLATEGREY);

let texasPowerLineGeoJsonDataSource;
loadPointsGeoJsonSource(texasPowerLineGeoJsonDataSource, 'TexasPowerLines', '/texas_power_lines.geojson', '/marker-red-icon.png');

let texasPowerSubStationsGeoJsonDataSource;
loadPointsGeoJsonSource(texasPowerSubStationsGeoJsonDataSource, 'TexasPowerSubStations', '/texas_power_substation_data.geojson', '/marker-red-icon.png', true);

async function loadPointsGeoJsonSource(geoJsonDataSource, name, url, iconUrl, showCircle){

	if(!geoJsonDataSource){
		
		Cesium.GeoJsonDataSource.load(url, {
			// stroke: markerColor, // Default stroke color for polygons and lines
			// markerColor : markerColor,
			// markerSymbol: 'circle',
			// markerSize : 25,
			clampToGround: true,
		}).then(dataSource => {
			dataSource.name = name;
			viewer.dataSources.add(dataSource);
			//console.log(dataSource);
			geoJsonDataSource = dataSource;
			//Customize each entity's icon
			dataSource.entities.values.forEach(function(entity) {
			  // Check if entity has a position (point) property
			  if (Cesium.defined(entity.position)) {
				 if(showCircle){
					/*const capacityProperty = entity.properties["Capacity (MW)"]; // Assuming 'voltage' is the property in your GeoJSON
					let capacity = Cesium.defined(capacityProperty.getValue) ? capacityProperty.getValue() : capacityProperty;
					
					// Scale the radius based on the capacity value
					const radius = capacity * 2; // Adjust the multiplier as needed to get the desired size

					// Add the ellipse to the entity instead of a billboard
					entity.ellipse = new Cesium.EllipseGraphics({
						semiMajorAxis: new Cesium.CallbackProperty(() => { 
										const cameraHeight = viewer.camera.positionCartographic.height;   return cameraHeight / 50; 
									}, false),
						semiMinorAxis: new Cesium.CallbackProperty(() => { 
										const cameraHeight = viewer.camera.positionCartographic.height; return cameraHeight / 50;
									}, false), 
						material: Cesium.Color.GREEN.withAlpha(0.5),  // Customize color and transparency
						height: 0,               // Ensure the circle sits at ground level
						outline: true,           // Outline the circle if desired
						outlineColor: Cesium.Color.BLACK,
						outlineWidth: 2,
						
					});*/
					const plantType = entity.properties["Type"];
					const capacityProperty = entity.properties["Capacity (MW)"];
					let capacity = Cesium.defined(capacityProperty.getValue) ? capacityProperty.getValue() : capacityProperty;
					let type = Cesium.defined(plantType.getValue) ? plantType.getValue() : plantType;
					// Scale the radius based on the capacity value

					// const startColor = Cesium.Color.BLUE;
					// const endColor = Cesium.Color.RED;
					// let pixelColor = Cesium.Color.lerp(startColor, endColor, 0.8, new Cesium.Color());
					
//					let pixelColor = Cesium.Color.RED;

					const pixelSize = fnPixelSize(capacity);
					// if (type === "Energy Storage System"){
					// 	pixelColor = Cesium.Color.BLUE;
					// }else if (type === "Wind Onshore"){
					// 	pixelColor = Cesium.Color.GREEN;						
					// }else if (type === "Energy Storage System;PV Array (Photo Voltaic/solar)"){
					// 	pixelColor = Cesium.Color.MAGENTA;						
					// } 
					// const pixelColor = Cesium.Color.RED.darken (0.5);
					 const pixelColor = fnPixelColor(type);
					 entity.point = { 
						 pixelSize: pixelSize, // Size of the point in pixels 
						 color: pixelColor, // Point color 
						 outlineColor: Cesium.Color.WHITE, // Outline color 
						 outlineWidth: 2, // Outline width in pixels 
					}
					
					// Remove any existing billboard to avoid the pin being shown
					entity.billboard = undefined;
					 
				 }else{
					 
					entity.billboard = new Cesium.BillboardGraphics({
						  image: iconUrl, // Path to your custom icon image
						  width: 32,   // Set the width of the icon
						  height: 32,  // Set the height of the icon
						  scale: 1.0,
						  verticalOrigin: Cesium.VerticalOrigin.BOTTOM // Positioning at the bottom
					  });	 
				 }
				 

			  } else if (Cesium.defined(entity.polyline)) {
				
				const lineVoltage = entity.properties["voltage"];
				let lv = Cesium.defined(lineVoltage) ? lineVoltage.getValue() : "000";
				const [lvColor, lvWidth] = fnLineVoltageColor(lv);

					// For LineString entities, customize the polyline appearance
					entity.polyline.width = 3;
					entity.polyline.material = lvColor; // Set line color and transparency
					//entity.polyline.material = Cesium.Color.GREEN; // Set line color and transparency
					//entity.polyline.outlineWidth = lvWidth; // Outline width
					//entity.polyline.outlineColor = Cesium.Color.BLACK; // Outline color
					entity.polyline.height = 0; // Disable terrain clamping to enable outlines
					
			  }else if (Cesium.defined(entity.polygon)) {
				    
					entity.polygon.material = Cesium.Color.BLUE.withAlpha(0.5);
					entity.polygon.outline = true;
					entity.polygon.outlineColor = Cesium.Color.BLACK;
					entity.polygon.height = 0; // Flat appearance
					entity.polygon.extrudedHeight = 0;
					//Dispay Pin on Polygon
					// Get the center of the polygon for placing the pin
					const polygonCenter = Cesium.BoundingSphere.fromPoints(entity.polygon.hierarchy.getValue(Cesium.JulianDate.now()).positions).center;
					const cartographic = Cesium.Cartographic.fromCartesian(polygonCenter);
					const latitude = Cesium.Math.toDegrees(cartographic.latitude);
					const longitude = Cesium.Math.toDegrees(cartographic.longitude);

					// Create a new entity for the pin at the center of the polygon
					dataSource.entities.add({
						position: Cesium.Cartesian3.fromDegrees(longitude, latitude),
						billboard: {
							image: iconUrl, // Path to your custom icon image
							width: 4,
							height: 4,
							scale: 1.0,
							verticalOrigin: Cesium.VerticalOrigin.BOTTOM
						}
					});
				}
			});
			geoJsonDataSource.show=false;//First time load so set false.
			// Attempt to access and modify the entities immediately
			// viewer.zoomTo(dataSource); // Optionally zoom to the data source
		})
		.catch(error => {
			console.error('Error loading GeoJSON:', error);
		});
	}else{
		geoJsonDataSource.show=true;
	}
}

function fnLineVoltageColor (lv) {

	if (lv.startsWith("69")){
		const width = 2;
		const color = Cesium.Color.lerp(Cesium.Color.RED, Cesium.Color.YELLOW, 0.5, new Cesium.Color());
	 	return [color, width];
	}else if (lv.startsWith("115")){
		const width = 3;
		const color = Cesium.Color.lerp(Cesium.Color.RED, Cesium.Color.WHITE, 0.5, new Cesium.Color());
		return [color, width];
	}else if (lv.startsWith("138")){
		const width = 4;
		const color = Cesium.Color.fromCssColorString("brown");
		// const color = Cesium.Color.lerp(Cesium.Color.BLUE, Cesium.Color.RED, 0.5, new Cesium.Color());
		return [color, width];
	}else if (lv.startsWith("230")){
		const width = 4;
		const color = Cesium.Color.BLUE;
		return [color, width];
	}else if (lv.startsWith("345")){
		const width = 4;
		const color = Cesium.Color.GREEN;
		return [color, width];
	}else if (lv.startsWith("500")){
		const width = 5;
		const color = Cesium.Color.RED;
		return [color, width];
	}else if (lv.startsWith("000")){
		const width = 2;
		const color = Cesium.Color.BLACK;
		return [color, width];
	}else {
		const width = 2;
		const color = Cesium.Color.BLACK;
		return [color, width];
	}
}

function fnPixelColor (str) {

	if (str.startsWith("CCGT")){
		const color = Cesium.Color.RED;
	 	return color;
	}else if (str.startsWith("CHP")){
		const color = Cesium.Color.lerp(Cesium.Color.RED, Cesium.Color.WHITE, 0.5, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Coal")){
		const color = Cesium.Color.lerp(Cesium.Color.BLUE, Cesium.Color.WHITE, 0.7, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Demand")){
		const color = Cesium.Color.lerp(Cesium.Color.BLUE, Cesium.Color.WHITE, 0.2, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Energy")){
		const color = Cesium.Color.GREEN;
	 	return color;
	}else if (str.startsWith("Gas")){
		const color = Cesium.Color.lerp(Cesium.Color.GREEN, Cesium.Color.WHITE, 0.2, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Hydro")){
		const color = Cesium.Color.lerp(Cesium.Color.BLUE, Cesium.Color.GREEN, 0.2, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Nuclear")){
		const color = Cesium.Color.lerp(Cesium.Color.RED, Cesium.Color.GREEN, 0.2, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("OCGT")){
		const color = Cesium.Color.lerp(Cesium.Color.RED, Cesium.Color.BLACK, 0.2, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Oil")){
		const color = Cesium.Color.MAGENTA;
	 	return color;
	}else if (str.startsWith("PV")){
		const color = Cesium.Color.lerp(Cesium.Color.RED, Cesium.Color.YELLOW, 0.2, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Pump")){
		const color = Cesium.Color.lerp(Cesium.Color.RED, Cesium.Color.CYAN, 0.4, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Reactive")){
		const color = Cesium.Color.BLUE;
	 	return color;
	}else if (str.startsWith("Thermal")){
		const color = Cesium.Color.lerp(Cesium.Color.RED, Cesium.Color.BLUE, 0.5, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Tidal")){
		const color = Cesium.Color.lerp(Cesium.Color.RED, Cesium.Color.GREEN, 0.2, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Waste")){
		const color = Cesium.Color.lerp(Cesium.Color.WHITE, Cesium.Color.BLACK, 0.5, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Wind Offshore")){
		const color = Cesium.Color.lerp(Cesium.Color.WHITE, Cesium.Color.GREEN, 0.5, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Wind Onshore")){
		const color = Cesium.Color.lerp(Cesium.Color.RED, Cesium.Color.YELLOW, 0.5, new Cesium.Color());
	 	return color;
	}else {
		const color = Cesium.Color.RED;
	 	return color;
	}
}

function fnPixelSize (capacity) {
	if (capacity == 0) {
		const size = 5;
		return size;
	}else if (capacity <= 25) {
		const size = 10;
		return size;
	}else if (capacity <= 100) {
		const size = 15;
		return size;
	}else if (capacity <= 500) {
		const size = 20;
		return size;
	}else if (capacity <= 1000) {
		const size = 25;
		return size;
	}else if (capacity <= 2000) {
		const size = 30;
		return size;
	}else if (capacity <= 3000) {
		const size = 40;
		return size;
	}else {
		const size = 50;
		return size;
	}
}

const powerSourceElements3d = document.querySelectorAll('.sidebar .menu-section #germanySection > input[type="checkbox"], .sidebar .menu-section #ukSection > input[type="checkbox"], .sidebar .menu-section #usSection > input[type="checkbox"]');
for (let powerSourceElement of powerSourceElements3d) {
   powerSourceElement.addEventListener ('change', function (){
    
	let mapGrid = document.getElementById('mapGrid');
	let chartGrid = document.getElementById('chartGrid');
	
	if(this.value === 'UKEnergyChart'){
		mapGrid.style.display = 'none';
		chartGrid.style.display = 'block';
	}else{
		mapGrid.style.display = 'block';
		chartGrid.style.display = 'none';
		let powerSources = viewer.dataSources.getByName(this.value);
		//console.log (powerSources[0]);
		if (powerSources.length > 0) {
			if(this.checked){
				powerSources[0].show = true;
			}else{
				powerSources[0].show = false;
			}
		}
	}
    
  });
}



function setCameraControls(enabled) {
    viewer.scene.screenSpaceCameraController.enableRotate = enabled;
    viewer.scene.screenSpaceCameraController.enableTranslate = enabled;
    viewer.scene.screenSpaceCameraController.enableZoom = enabled;
    viewer.scene.screenSpaceCameraController.enableTilt = enabled;
    viewer.scene.screenSpaceCameraController.enableLook = enabled;
}

let activeLinePositions = [];
let activeLineLabel;
let activePolyline;
let activeDrawingHandler;
let activeCircle = null;  // To store the current circle entity
let activeCircleCenterPosition = null; // To store the center position
let activeCircleLabel;
const allPolylines = [];  // Store all completed polylines here
const allCircles = [];
let isDrawing = false;
let lineID = 0;
let geomObjectsByID = new Array();
let pointID = 0;
let circleID = 0;
let clickNum = 0;

function createLabelForLine(){
	// Create a new distance label to follow the current line's end point
    activeLineLabel = viewer.entities.add({
        label: {
            show: true,
			text: "Distance: 0 km",
			font: "16px bold Helvetica, Arial, sans-serif",  // Larger, more readable font with a clean family
			fillColor: Cesium.Color.WHITE,  // White text color for contrast
			outlineColor: Cesium.Color.BLACK,  // Black outline for clarity
			outlineWidth: 3,  // Thicker outline for better contrast
			style: Cesium.LabelStyle.FILL_AND_OUTLINE,  // Solid fill with outline for better visibility
			showBackground: true,  // Display background behind the text
			backgroundColor: new Cesium.Color(0, 0, 0, 0.7),  // Slightly transparent black background
			backgroundPadding: new Cesium.Cartesian2(10, 6),  // More padding around the text for a spacious look
			verticalOrigin: Cesium.VerticalOrigin.BOTTOM,  // Position the label below the point
			horizontalOrigin: Cesium.HorizontalOrigin.LEFT,  // Position the label to the right of the point
			zIndex: 2,  // Ensure the label appears on top of other entities
			// Shadow effect for better readability
			shadowColor: Cesium.Color.BLACK.withAlpha(0.5),  // Light shadow effect to improve contrast
			shadowOffsetX: 2,  // Slight horizontal offset for the shadow
			shadowOffsetY: 2,  // Slight vertical offset for the shadow
			//pixelOffset: new Cesium.Cartesian2(15, -15),
        },
    });
}

function createLabelForLineNew(){
	// Create a new distance label to follow the current line's end point
    return {
            show: true,
			text: "Distance: 0 km",
			font: "16px bold Helvetica, Arial, sans-serif",  // Larger, more readable font with a clean family
			fillColor: Cesium.Color.WHITE,  // White text color for contrast
			outlineColor: Cesium.Color.BLACK,  // Black outline for clarity
			outlineWidth: 3,  // Thicker outline for better contrast
			style: Cesium.LabelStyle.FILL_AND_OUTLINE,  // Solid fill with outline for better visibility
			showBackground: true,  // Display background behind the text
			backgroundColor: new Cesium.Color(0, 0, 0, 0.7),  // Slightly transparent black background
			backgroundPadding: new Cesium.Cartesian2(10, 6),  // More padding around the text for a spacious look
			verticalOrigin: Cesium.VerticalOrigin.BOTTOM,  // Position the label below the point
			horizontalOrigin: Cesium.HorizontalOrigin.LEFT,  // Position the label to the right of the point
			zIndex: 2,  // Ensure the label appears on top of other entities
			// Shadow effect for better readability
			shadowColor: Cesium.Color.BLACK.withAlpha(0.5),  // Light shadow effect to improve contrast
			shadowOffsetX: 2,  // Slight horizontal offset for the shadow
			shadowOffsetY: 2,  // Slight vertical offset for the shadow
			//pixelOffset: new Cesium.Cartesian2(15, -15),
        };
    
}

function createLabelForPoint(id, latitude, longitude){
	// Create a new distance label to follow the current line's end point
    return {
		show: true,
		text: id + ": " + latitude.toFixed (2) + ", " + longitude.toFixed(2),
		font: "16px bold Helvetica, Arial, sans-serif",  // Larger, more readable font with a clean family
		fillColor: Cesium.Color.WHITE,  // White text color for contrast
		outlineColor: Cesium.Color.BLACK,  // Black outline for clarity
		outlineWidth: 3,  // Thicker outline for better contrast
		//style: Cesium.LabelStyle.FILL_AND_OUTLINE,  // Solid fill with outline for better visibility
		showBackground: true,  // Display background behind the text
		backgroundColor: new Cesium.Color(0, 255, 0, 0.7),  // Slightly transparent black background
		backgroundPadding: new Cesium.Cartesian2(10, 6),  // More padding around the text for a spacious look
		//pixelOffset: new Cesium.Cartesian2(15, -15),  // Slightly larger offset to avoid overlap with the pointer
		verticalOrigin: Cesium.VerticalOrigin.BOTTOM,  // Position the label below the point
		horizontalOrigin: Cesium.HorizontalOrigin.LEFT,  // Position the label to the right of the point
		zIndex: 2,  // Ensure the label appears on top of other entities
		// Shadow effect for better readability
		shadowColor: Cesium.Color.BLACK.withAlpha(0.5),  // Light shadow effect to improve contrast
		shadowOffsetX: 2,  // Slight horizontal offset for the shadow
		shadowOffsetY: 2,  // Slight vertical offset for the shadow};
	};
}


function createLabelForCircle(){
	// Create a new distance label to follow the current line's end point
    activeCircleLabel = viewer.entities.add({
        label: {
            show: true,
            text: "Radius: 0 km",
            font: "16px bold Helvetica, Arial, sans-serif",  // Larger, more readable font with a clean family
            fillColor: Cesium.Color.WHITE,  // White text color for contrast
            //style: Cesium.LabelStyle.FILL_AND_OUTLINE,  // Solid fill with outline for better visibility
            showBackground: true,  // Display background behind the text
            backgroundColor: new Cesium.Color(0, 255, 0, 0.7),  // Slightly transparent black background
            backgroundPadding: new Cesium.Cartesian2(10, 6),  // More padding around the text for a spacious look
            //pixelOffset: new Cesium.Cartesian2(15, -15),  // Slightly larger offset to avoid overlap with the pointer
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,  // Position the label below the point
            horizontalOrigin: Cesium.HorizontalOrigin.LEFT,  // Position the label to the right of the point
            zIndex: 2,  // Ensure the label appears on top of other entities
            // Shadow effect for better readability
            shadowColor: Cesium.Color.BLACK.withAlpha(0.5),  // Light shadow effect to improve contrast
            shadowOffsetX: 2,  // Slight horizontal offset for the shadow
            shadowOffsetY: 2,  // Slight vertical offset for the shadow
        },
    });
}


function startNewLineDrawing(){
	activeLinePositions = [];
	createLabelForLine();
	lineID++;
    // Create a new polyline entity with a dashed line to show the drawing in progress
	let id = 'Line-'+lineID;
    activePolyline = viewer.entities.add({
		id: id,
        polyline: {
            positions: new Cesium.CallbackProperty(() => activeLinePositions, false),
            width: 3,
            material: Cesium.Color.BLUE
        },
		//label: createLabelForLineNew() RnD on this, may solve the undo issue with label
    });
	
    // Set up event handlers for drawing
    activeDrawingHandler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);

    // Add points to the line on left click
    activeDrawingHandler.setInputAction((event) => {
		
//			geomObjectsByID.push({ type: 'line', line: activePolyline, label: activeLineLabel });
			activeCircleCenterPosition = null;
			const cartesian = viewer.camera.pickEllipsoid(event.position, viewer.scene.globe.ellipsoid);
			if (cartesian) {
				activeLinePositions.push(cartesian);
			}
			
			if (activeLineLabel && activeLinePositions.length > 0) {
				//console.log("Calculate the distance");
				const distance = calculateDistance(activeLinePositions);
				//console.log("Calculate the distance "+distance);
				activeLineLabel.label.text = `${activePolyline.id} : ${(distance / 1000).toFixed(2)} km`;
			}
		
		if(isDrawing){
			isDrawing = false;
			finalizeCurrentLine();
		}else{
			geomObjectsByID.push({ type: 'line', line: activePolyline, label: activeLineLabel });
			isDrawing = true;
		}
        
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);


    // Update the distance label on mouse move
    activeDrawingHandler.setInputAction((movement) => {
        const cartesian = viewer.camera.pickEllipsoid(movement.endPosition, viewer.scene.globe.ellipsoid);
        if (cartesian && activeLinePositions.length > 0) {
			let mouseLinePositions = [];
			mouseLinePositions.push(activeLinePositions[0]);
			mouseLinePositions.push(cartesian);
            const distance = calculateDistance(mouseLinePositions);
            activeLineLabel.position = cartesian;
            activeLineLabel.label.text = `${activePolyline.id} : ${(distance / 1000).toFixed(2)} km`;
        }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
}

// Function to finalize the current line and stop drawing
function finalizeCurrentLine() {
    // Position the distance label at the end of the line
    if (activeLinePositions.length > 1) {
        const lastPoint = activeLinePositions[activeLinePositions.length - 1];
		activeLineLabel.position = Cesium.Cartesian3.midpoint(activeLinePositions[0], lastPoint, new Cesium.Cartesian3());
		//activeLineLabel.position = activeLinePositions[0]; // at start of line
       // activeLineLabel.position = lastPoint; // at the end of line
    }
	
	// Make the line solid by setting a permanent material color
    if (activePolyline) {
		activePolyline.polyline.positions = activeLinePositions;
		allPolylines.push(activePolyline);
    }
	

    // Remove the drawing handler to stop adding points to this line
    if (activeDrawingHandler) {
        activeDrawingHandler.destroy();
    }

    startNewDrawing();
}

function startPointDrawing() {
    // Convert the screen position to a Cartesian3 position
	activeDrawingHandler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
	
	activeDrawingHandler.setInputAction((event) => {
		const cartesian = viewer.scene.pickPosition(event.position);
		
		if (Cesium.defined(cartesian)) {	
			// Convert the Cartesian3 position to geographic coordinates
			const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
			const latitude = Cesium.Math.toDegrees(cartographic.latitude);
			const longitude = Cesium.Math.toDegrees(cartographic.longitude);
			
			// Log latitude and longitude
			//console.log("Latitude:", latitude, "Longitude:", longitude);

			// Create the point entity at the clicked location
			let id = 'Point-' + pointID;
			let activePoint = viewer.entities.add({
				id: id,
				position: cartesian,
				point: {
					pixelSize: 10,
					color: Cesium.Color.RED,
					outlineColor: Cesium.Color.WHITE,
					outlineWidth: 2 
				},
				label: createLabelForPoint(id, latitude, longitude),
			});
			pointID = pointID + 1;
			geomObjectsByID.push({ type: 'point', point: activePoint});
		}
		
	}, Cesium.ScreenSpaceEventType.LEFT_DOWN);
}

function startCircleDrwaing(){
	createLabelForCircle();
	circleID++;
	let circleId = 'Circle-' + circleID;
	activeCircle = viewer.entities.add({
              id: circleId,
             // position: new Cesium.CallbackProperty(() => activeLinePositions, false),
              ellipse: {
                  semiMajorAxis: 1.0,  // Initial radius
                  semiMinorAxis: 1.0,
                  material: Cesium.Color.BLUE.withAlpha(0.5),
              },
          });
	
	activeDrawingHandler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);

    // Add points to the line on left click
    activeDrawingHandler.setInputAction((event) => {
		
		if(!isDrawing){
			
			geomObjectsByID.push({ type: 'circle', circle: activeCircle, label: activeCircleLabel });
			const cartesian = viewer.camera.pickEllipsoid(event.position, viewer.scene.globe.ellipsoid);
			if (cartesian) {
				activeCircle.position = cartesian;
				activeCircleLabel.position = cartesian;
				activeCircleCenterPosition = cartesian;
				
			}
			isDrawing = true;
			
		}else{
			isDrawing = false;
			finalizeCurrentCircle();
			activeCircleCenterPosition = null;
			setCameraControls(true);
		}
        
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
	
	activeDrawingHandler.setInputAction((event) => {
		  
			if (isDrawing && Cesium.defined(activeCircleCenterPosition)) {
			  const currentMousePosition = viewer.scene.pickPosition(event.endPosition);

			  if (Cesium.defined(currentMousePosition)) {
				try {
					
					let mouseLinePositions = [];
					mouseLinePositions.push(activeCircleCenterPosition);
					mouseLinePositions.push(currentMousePosition);
					const radius = calculateDistance(mouseLinePositions);
					if (!isNaN(radius)) {
						activeCircle.ellipse.semiMajorAxis = radius;
						activeCircle.ellipse.semiMinorAxis = radius;
						updateActiveCircleLabel (radius, currentMousePosition);
		 
					}
				} catch (error) {
					console.error('Error calculating or setting radius:', error);
				}
				  // Update the circle's radius dynamically
				  //activeCircle.ellipse.semiMajorAxis = radius;
				  //activeCircle.ellipse.semiMinorAxis = radius;
			  }
			}
		  
		}, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
	
}

function updateActiveCircleLabel(radius, mousePosition) {
  let txt = "Radius: " + (radius / 1000).toFixed(2) + "km";
  if (activeCircleLabel) {
      activeCircleLabel.label.text = txt; // Convert meters to km
      const cartesian = viewer.camera.pickEllipsoid(mousePosition, viewer.scene.globe.ellipsoid);
      if (cartesian) {
          activeCircleLabel.position = cartesian; // Update label position to follow the mouse
      }
  }
}

function finalizeCurrentCircle() {
    
	
	// Make the line solid by setting a permanent material color
    if (activeCircle) {
		allCircles.push(activeCircle);
    }
	

    // Remove the drawing handler to stop adding points to this line
    if (activeDrawingHandler) {
        activeDrawingHandler.destroy();
    }

    startNewDrawing();
}


function startNewDrawing() {
    if(typeSelect.value === 'LineString'){
		
		resetAllActiveDrawing();
		startNewLineDrawing();
		
	}else if(typeSelect.value === 'Point'){
		
		resetAllActiveDrawing();
		startPointDrawing();
		
	}else if(typeSelect.value === 'Circle'){
		
		resetAllActiveDrawing();
		startCircleDrwaing();
		
	}else if(typeSelect.value === 'None'){
		
		resetAllActiveDrawing();
	}
}

function resetAllActiveDrawing() {
	if(activeDrawingHandler && !activeDrawingHandler.isDestroyed()){
			activeDrawingHandler.destroy();	
	}
}



// Function to calculate the total distance between positions
function calculateDistance(positions) {
    let distance = 0;
    for (let i = 1; i < positions.length; i++) {
        const point1 = Cesium.Cartographic.fromCartesian(positions[i - 1]);
        const point2 = Cesium.Cartographic.fromCartesian(positions[i]);
        distance += Cesium.Cartesian3.distance(
            Cesium.Cartesian3.fromRadians(point1.longitude, point1.latitude),
            Cesium.Cartesian3.fromRadians(point2.longitude, point2.latitude)
        );
    }
    return distance;
}


typeSelect.onchange = function () {
	startNewDrawing();
};


document.getElementById('undo').addEventListener('click', function () {
  if (geomObjectsByID.length) {
    let entityObj = geomObjectsByID[geomObjectsByID.length - 1]
    
	if (entityObj.type === 'circle') {
        viewer.entities.remove(entityObj.circle);
        viewer.entities.remove(entityObj.label);
 
    }else if (entityObj.type === 'line') {
        viewer.entities.remove(entityObj.line);
        viewer.entities.remove(entityObj.label);
 
    }else if (entityObj.type === 'point') {
		viewer.entities.remove(entityObj.point);
    } else {
      console.log ("unknown type...");
    }
    geomObjectsByID.pop ();  
  } else {
    //Nothing to remove!
  }
});
let step = 500000.0;

document.getElementById('zoomIn').addEventListener('click', function() {
//	viewer.scene.screenSpaceCameraController.enableCollisionDetection = false;
	const cartographic = Cesium.Cartographic.fromCartesian(viewer.camera.position);
    const currentHeight = cartographic.height;

    // Ensure we don’t go below a safe height
//    const zoomStep = Math.min(step, currentHeight); // Ensure at least 10 meters above the surface
	if (currentHeight <= (step * 2)) {
		if (step == 500000){
			console.log ("old step = ", step);
			step = 100000.0;
			console.log ("reducing step to ", step);
		} else if (step == 100000){
			console.log ("old step = ", step);
			step = 10000;
			console.log ("reducing step to ", step);
		} else if (step == 10000) {
			console.log ("old step = ", step);
			step = 1000;
			console.log ("reducing step to ", step);
		} else if (step == 1000) {
			console.log ("old step = ", step);
			step = 100;
			console.log ("reducing step to ", step);
		} else if (step == 100) {
			console.log ("old step = ", step);
			step = 0;
			console.log ("reducing step to ", step);
		}
	} else {
		if (currentHeight >= 2000000) {
			step = 500000;
		} else if (currentHeight >= 500000) {
			step = 100000;
		} else if (currentHeight >= 30000) {
			step = 10000;
		} else if (currentHeight >= 3000) {
			step = 1000;
		} else if (currentHeight >= 1000) {
			step = 100;
		} else if (currentHeight <= 300) {
			step = 0;
		}
	}	
	console.log ("currentHeight", currentHeight);
	console.log ("new step", step);
	viewer.camera.zoomIn(step); // Adjust zoom distance as needed

	//    viewer.camera.zoomIn(zoomStep);
});

document.getElementById('zoomOut').addEventListener('click', function() {
    viewer.camera.zoomOut(500000); // Adjust zoom distance as needed
});
