import { Chart, registerables } from 'chart.js';

// Register all components (including pie chart support)
Chart.register(...registerables);

const chartTypeSelect = document.getElementById('chartType');

chartTypeSelect.onchange = function () {
	if(chartTypeSelect.value === 'myPieChart'){
		
		document.getElementById('myPieChart').style.display = 'block';
		document.getElementById('myBarChart').style.display = 'none';
		
	}else if(chartTypeSelect.value === 'myBarChart'){
		
		document.getElementById('myPieChart').style.display = 'none';
		document.getElementById('myBarChart').style.display = 'block';
		
	}else if(chartTypeSelect.value === 'None'){
		
		document.getElementById('myPieChart').style.display = 'none';
		document.getElementById('myBarChart').style.display = 'none';
	}
};

// Function to fetch GeoJSON data from a URL
async function fetchGeoJson(url) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const geojson = await response.json();
    return geojson;
  } catch (error) {
    console.error('Error fetching GeoJSON:', error);
    return null;
  }
}

// Function to aggregate GeoJSON data
function aggregateGeoJsonData(geojson) {
  const data = {};

  geojson.features.forEach(feature => {
    let type = feature.properties['Type']; // e.g., "solar", "wind"
    const capacity = feature.properties['Capacity (MW)']; // e.g., 50, 30

	//type = type.trim().substring(0, 4);
    // Aggregate capacity by type
    if (data[type]) {
      data[type] += capacity;
    } else {
      data[type] = capacity;
    }
  });

//  console.log('Aggregated Data:', data);
  return data;
}

// Function to create a pie chart with aggregated data
let pieChartWithCategory;
function createPieChart(data) {
  const ctx = document.getElementById('myPieChart').getContext('2d');
  pieChartWithCategory = new Chart(ctx, {
    type: 'pie',
    data: {
      labels: Object.keys(data),           // ["solar", "wind"]
      datasets: [{
        data: Object.values(data),         // [90, 30]
       // backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'] // Colors for each section
      }]
    },
	options: {
		responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
				position: 'left', // Move legend to the left side
				labels: {
						boxWidth: 5,
                        font: {
                            size: 8 // Set the legend font size smaller
                        }
                    }
            }
        },
		layout: {
                padding: 20 // Add padding if you want extra space between the chart and legend
            }
		
    }
  });
}

// Function to create a bar chart with aggregated data
function createBarChart(data) {
  const ctx = document.getElementById('myBarChart').getContext('2d');
  new Chart(ctx, {
    type: 'bar',
    data: {
      labels: Object.keys(data),           // ["solar", "wind"]
      datasets: [{
        data: Object.values(data),         // [90, 30]
       // backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'] // Colors for each section
      }]
    }
  });
}

// Main function to fetch, process, and display the chart
async function renderChartFromGeoJson(url) {
  const geojson = await fetchGeoJson(url);

  if (geojson) {
    const aggregatedData = aggregateGeoJsonData(geojson);
    createPieChart(aggregatedData);

	createBarChart(aggregatedData);
  }
}

document.getElementById('downloadChart').addEventListener('click', function() {
        // Convert chart to an image URL
        const link = document.createElement('a');
        link.href = pieChartWithCategory.toBase64Image(); // Get image in PNG format
        link.download = 'myChart.png'; // Set filename
        link.click(); // Trigger the download
  });

// Call the main function with the GeoJSON file URL
const geojsonUrl = '/UK_Power_Plants.geojson';
renderChartFromGeoJson(geojsonUrl);
